import React, {useEffect, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider.js';
import {useParams, useLocation} from 'react-router-dom';
import {useNavigate} from '../../../routes/index.js';
import SectionActivityStatus from '../SectionActivityStatus/SectionActivityStatus.js';
import {getLocalStorageItem} from '../../../services/local-storage.js';
import {fetchModuleSectionList} from '../../../store/actions/sectionActions.js';
import {MANAGE_STUDENT_LESSON__PATH} from '../../../constants/routePaths.js';
import CustomModalStudent from '../../../components/CustomModalStudent/CustomModalStudent.js';
import HelpCard from '../../../components/HelpCard/HelpCard.js';
import {fetchStaticContent} from '../../../store/actions/helpActions';
import StudentDocViewer from '../../../components/StudentDocViewer/StudentDocViewer.js';
import './SectionWrapper.scss';
import {downloadFileAttachments} from '../../../store/actions/fileActions';
import {useIntl} from 'react-intl';
import HorizontalScrollButtons from '../../../components/HorizontalScrollButtons/HorizontalScrollButtons.js';

function SectionWrapper({
  backgroundImg,
  sectionWrapperClassName,
  helpBackgroundImg,
  helpBackgroundImgClass,
}) {
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();
  const {slug} = location.state || {};
  const selectedAvatarId = getLocalStorageItem('studentIslandAvatar');
  const [sortedSections, setSortedSections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const intl = useIntl();
  const sectionsList = useSelector(
    state => state?.section?.sectionsList?.data?.data,
  );

  const helpList = useSelector(state => state?.help?.StaticContentList?.data);

  const fetchHelpData = async () => {
    await dispatch(
      fetchStaticContent({
        pageName: 'help',
        loaderDispatch,
      }),
    );
  };

  useEffect(() => {
    fetchHelpData();
  }, []);

  useEffect(() => {
    if (sectionsList?.length > 0) {
      let sortedSectionArray = [...sectionsList];
      const sortedSectionsList = sortedSectionArray?.sort(
        (a, b) => a.module_element.id - b.module_element.id,
      );
      setSortedSections(sortedSectionsList);
    }
  }, [sectionsList]);

  useEffect(() => {
    document.body.scrollLeft = 0;
    document.documentElement.scrollLeft = 0;
  }, [sectionsList]);

  const calculateProgressOfPrevLesson = index => {
    const prevEleIndex = index - 1;
    const sectionElement = sortedSections[prevEleIndex];
    const progress = calculateProgress(
      sectionElement?.completions_lesson_count,
      sectionElement?.no_of_lessons,
    );
    return progress;
  };

  const calculateProgress = (completed, total) => {
    return total === 0 ? 0 : (completed / total) * 100;
  };

  useEffect(() => {
    fetchSectionData();
    document.body.classList.add('h-100', 'overflow-auto');
    document.documentElement.classList.add('h-100', 'overflow-auto');

    return () => {
      document.body.classList.remove('h-100', 'overflow-auto');
      document.documentElement.classList.remove('h-100', 'overflow-auto');
    };
  }, []);

  const fetchSectionData = async () => {
    await dispatch(fetchModuleSectionList({id, loaderDispatch}));
  };

  const handleButtonClick = (sectionId, sectionSlug) => {
    const cleanedSlug = sectionSlug.replace(/_/g, '');
    navigate(
      `${MANAGE_STUDENT_LESSON__PATH.replace(':id', sectionId).replace(
        ':section',
        cleanedSlug,
      )}`,
      {
        state: {
          id: id,
          slug: slug,
        },
      },
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeDocViewerModal = () => {
    setSelectedMedia(null);
    setIsPdfModalOpen(false);
  };

  const handleHelpCardClick = media => {
    setSelectedMedia(media);
    setIsPdfModalOpen(true);
  };

  const dialogHeaderContent = (
    <div className="help-header-wrapper">
      <div onClick={closeModal} className="back-icon">
        <img src="/images/back.svg" alt="back" />
      </div>
      <h2 className="help-header text-shadow">
        {intl.formatMessage({id: 'label.help'})}
      </h2>
    </div>
  );

  const onAttachmentsDownload = idMedia => {
    dispatch(downloadFileAttachments({idMedia, intl, loaderDispatch}));
  };

  const HelpDocViewerHeader = ({
    media,
    onBackDocs,
    onAttachmentsDownload,
    title,
  }) => {
    return (
      <div className="doc-view-modal-header">
        <img
          src="/images/modalBackIcon.svg"
          alt="Back icon"
          onClick={onBackDocs}
          className="docs-view-icon back-icon"
        />
        <div className="doc-viewer-file-name">{title}</div>
        <img
          src="/images/downloadIcon.svg"
          alt="Download icon"
          className="docs-view-icon"
          onClick={() => onAttachmentsDownload(media?.media?.id)}
        />
      </div>
    );
  };

  return (
    <div className={`student-section-wrapper ${sectionWrapperClassName}`}>
      <div className="scroll-container">
        <img
          className="student-section-container"
          src={backgroundImg}
          alt="island"
        />
        <img
          className={`${helpBackgroundImgClass} cursor`}
          src={helpBackgroundImg}
          alt="help-sign"
          onClick={openModal}
        />
        <div className="play-buttons">
          {sortedSections?.map((section, index) => (
            <SectionActivityStatus
              key={section.id}
              index={index}
              section={section}
              calculateProgress={calculateProgress}
              calculateProgressOfPrevLesson={calculateProgressOfPrevLesson}
              handleButtonClick={handleButtonClick}
            />
          ))}
        </div>
        {/* <img className="islandCar" src={selectedAvatarId?.path} alt="avatar" /> */}
      </div>
      {isModalOpen && (
        <CustomModalStudent
          open={isModalOpen || isPdfModalOpen}
          handleClose={closeModal}
          backgroundBlue={selectedMedia?.media ? false : true}
          modalWidth={'60%'}
          showHeader
          dialogHeader={
            selectedMedia?.media ? (
              <HelpDocViewerHeader
                onBackDocs={closeDocViewerModal}
                media={selectedMedia}
                onAttachmentsDownload={onAttachmentsDownload}
                title={selectedMedia.title}
              />
            ) : (
              dialogHeaderContent
            )
          }>
          {selectedMedia?.media ? (
            <StudentDocViewer
              docViewerClasssName="section-help-pdf"
              mediaPath={selectedMedia?.media?.path}
            />
          ) : (
            <div className="help-modal-wrapper">
              {helpList?.map((helpItem, index) => (
                <HelpCard
                  key={index}
                  heading={helpItem?.title}
                  imgPath="/images/back.svg"
                  onClick={() => handleHelpCardClick(helpItem)}
                />
              ))}
            </div>
          )}
        </CustomModalStudent>
      )}
      <HorizontalScrollButtons />
    </div>
  );
}

export default SectionWrapper;
