import React, {useEffect} from 'react';

const LessonPath = ({lesson, index, handleButtonClick, pebbleImg}) => {
  return (
    <div
      key={lesson.id}
      className={`${
        index % 2 === 0 ? 'even lesson-stone' : 'odd lesson-stone'
      }`}>
      <div className="montserrat-bold mb-4 lesson-name">{lesson.name}</div>
      <div class="lesson-btn-pebble">
        <div
          className={`play-button ${lesson?.module_element?.slug}`}
          onClick={() => handleButtonClick(lesson)}>
          {lesson?.is_completed ? (
            <img
              className="islandIco cursor"
              src="/images/moduleOrgBtn.svg"
              alt="Play Button"
            />
          ) : (
            <img
              className="islandIco cursor"
              src="/images/moduleGreenBtn.svg"
              alt="Play Button"
            />
          )}
        </div>
        <div className="lesson-path-pebble">
          <img src={pebbleImg} alt="pebble" />
        </div>
      </div>
      <div
        className={`lesson-path-line ${
          index % 2 === 0 ? 'even' : 'odd'
        }`}></div>
    </div>
  );
};

export default LessonPath;
