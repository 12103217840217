import React, {useEffect} from 'react';
import StarProgressButton from '../../../components/StarProgressButton/StarProgressButton.js';

const SectionActivityStatus = ({
  index,
  section,
  calculateProgress,
  calculateProgressOfPrevLesson,
  handleButtonClick,
}) => {
  const handleClick = () => {
    if (calculateProgressOfPrevLesson(index) < 100) {
      return;
    }
    handleButtonClick(section.id, section.module_element.slug);
  };

  return (
    <div
      key={section.id}
      className={`play-button ${section?.module_element?.slug}`}
      onClick={handleClick}>
      {index === 0 || calculateProgressOfPrevLesson(index) === 100 ? (
        calculateProgress(
          section?.completions_lesson_count,
          section?.no_of_lessons,
        ) === 100 ||
        calculateProgress(
          section?.completions_lesson_count,
          section?.no_of_lessons,
        ) > 1 ? (
          <>
            <StarProgressButton
              className="islandIcon cursor"
              progress={calculateProgress(
                section?.completions_lesson_count,
                section?.no_of_lessons,
              )}
              value={index + 1}
              title={section.module_element.name}
            />
          </>
        ) : (
          <img
            className="islandIco cursor"
            src="/images/playButon.svg"
            alt="Play Button"
          />
        )
      ) : (
        <img
          className="islandIco cursor-none"
          src="/images/lock.svg"
          alt="Lock Button"
        />
      )}
    </div>
  );
};

export default SectionActivityStatus;
